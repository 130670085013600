import React, { useState } from 'react';
import { StudentScores } from '../../../../models/subjectActivities';
import {
  Avatar,
  Button,
  Center,
  HStack,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import useAuth from '../../../../hooks/useAuth.hooks';
import { ScoreInputModal } from './ScoreInputModal';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

type Props = {
  students: StudentScores[];
  maxScore: number;
  minPassingScore: number;
  creator: {
    id: number;
    role: string;
  };
};

export const SubjectActivityStudentTable = ({ students, maxScore, creator }: Props) => {
  const [filterValue, setFilterValue] = useState('');
  const [selectedStudent, setSelectedStudent] = useState<StudentScores | null>(null);
  const {
    isOpen: scoreInputOpen,
    onOpen: onScoreInputOpen,
    onClose: onScoreInputClose,
  } = useDisclosure();
  const authContext = useAuth();

  return (
    <>
      <ScoreInputModal
        isOpen={scoreInputOpen}
        onClose={onScoreInputClose}
        student={selectedStudent}
        maxScore={maxScore}
      />
      <HStack
        w={{ base: '95vw', md: '80%', lg: '800px' }}
        justify={{ base: 'center', md: 'start' }}
      >
        <Input
          placeholder='Filtriraj po prezimenu, imenu ili indeksu'
          w='300px'
          type='text'
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          borderColor={'blue.700'}
        />
      </HStack>
      <TableContainer
        mt={'1rem'}
        w={'800px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>Prezime i ime</Th>
              <Th textAlign='center'>Broj Indexa</Th>
              <Th textAlign='center'>Bodovi / max. bodovi</Th>
              <Th textAlign='center'>opcije</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.length > 0 ? (
              students
                .filter(
                  (student) =>
                    student.indexNumber.includes(filterValue) ||
                    student.lastName.toLowerCase().includes(filterValue.toLowerCase()) ||
                    student.firstName.toLowerCase().includes(filterValue.toLowerCase())
                )
                .map((attendance, index) => (
                  <Tr key={attendance.id}>
                    <Td textAlign='center'>{index + 1}</Td>
                    <Td textAlign='center'>
                      <Avatar
                        size={'sm'}
                        name={`${attendance.lastName} ${attendance.firstName}`}
                        src={attendance.profileImage}
                      />
                    </Td>

                    <Td textAlign='center'>
                      <StudentNameSubjectStats
                        firstName={attendance.firstName}
                        lastName={attendance.lastName}
                        navigateLink={`/profesori/student/${attendance.id}`}
                        hasCompletedSubject={attendance.hasCompletedSubject}
                        repeater={attendance.repeater}
                      />
                    </Td>
                    <Td textAlign='center'>{attendance.indexNumber}</Td>
                    <Td w='10%' textAlign={'center'}>
                      {attendance.score} / {maxScore}
                    </Td>
                    <Td textAlign='center'>
                      <Button
                        size='xs'
                        colorScheme='blue'
                        onClick={() => {
                          setSelectedStudent(attendance);
                          onScoreInputOpen();
                        }}
                        isDisabled={
                          !(
                            authContext.user?.role === creator.role &&
                            authContext.user?.sub === creator.id
                          )
                        }
                      >
                        Unesi rezultat
                      </Button>
                    </Td>
                  </Tr>
                ))
            ) : (
              <Tr>
                <Td colSpan={6}>
                  <Center>
                    <Text> Nema prisutnih studenata na predavanju </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {students.length > 5 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>Prezime i ime</Th>
                <Th textAlign='center'>Broj Indexa</Th>
                <Th textAlign='center'>bodovi / max. bodovi</Th>
                <Th textAlign='center'>opcije</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
