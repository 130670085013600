import { Flex, Heading, SimpleGrid, VStack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { CreateFaculty } from '../universitys/comp/CreateFaculty';
import { CreateStudyProgram } from '../universitys/comp/CreateStudyProgram';
import { AllOrganizationsTable } from './comp/AllOrganizationsTable';
import { CreateUniversity } from './comp/CreateUniversity';
import { CreateFacultyMainAdmin } from './comp/CreateFacultyMainAdmin';
import { CreateStudyProgramMainAdmin } from './comp/CreateStudyProgramMainAdmin';

type Props = {};

export const SpecialPermision = (props: Props) => {
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w={'100%'}
    >
      <Heading fontSize={'2xl'} py={3} pb={5}>
        Specijalne permisije
      </Heading>
      <SimpleGrid
        columns={{
          sm: 1,
          md: 2,
          lg: 3,
        }}
        spacing={'50px'}
        pb={10}
      >
        <VStack>
          <Heading pb={3} fontSize={'xl'}>
            Kreiraj Univerzitet
          </Heading>
          <CreateUniversity />
        </VStack>
        <VStack>
          <Heading fontSize={'xl'} pb={3}>
            Kreiraj Fakultet
          </Heading>
          <CreateFacultyMainAdmin />
        </VStack>
        <VStack>
          <Heading fontSize={'xl'} pb={3}>
            Kreiraj studijski program
          </Heading>
          <CreateStudyProgramMainAdmin />
        </VStack>
      </SimpleGrid>
      <Heading fontSize={'xl'} py={5}>
        Sve organizacije
      </Heading>
      <AllOrganizationsTable />
    </Flex>
  );
};
