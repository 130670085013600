import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Card,
  Center,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
  chakra,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { ExamsPreview, partialExamPreview } from '../../../../models/exams';
import { CreatePartialExamModal } from './modals/CreatePartialExamModal';
import { YesNoInputConfirmModal } from '../../../../components/general/YesNoInputConfirmModal';
import {
  useDeleteExamMutation,
  useDeletePartialExamMutation,
} from '../../../../services/mutations';
import { EditExamModal } from './modals/EditExamModal';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetExamInfo, useGetExamStudentsStats } from '../../../../services/queries';
import { RepeatExamModal } from './modals/RepeatExamModal';
import { EditPartialExamModal } from './modals/EditPartialExamModal';
import { ExamStatsButton } from './ExamStatsButton';

interface PartialExamProps {
  partialExam: partialExamPreview;
  examScheduleID: number;
}

const PartialExamComp = ({ partialExam, examScheduleID }: PartialExamProps) => {
  const {
    isOpen: isDeletePartialExamOpen,
    onOpen: onDeletePartialExamOpen,
    onClose: onDeletePartialExamClose,
  } = useDisclosure();

  const {
    isOpen: isEditPartialExamOpen,
    onOpen: onEditPartialExamOpen,
    onClose: onEditPartialExamClose,
  } = useDisclosure();

  const deletePartialExam = useDeletePartialExamMutation(examScheduleID);

  return (
    <>
      <EditPartialExamModal
        partialExamID={partialExam.id}
        isOpen={isEditPartialExamOpen}
        onClose={onEditPartialExamClose}
      />
      <YesNoInputConfirmModal
        isOpen={isDeletePartialExamOpen}
        onClose={onDeletePartialExamClose}
        title={'Brisanje parcijalnog ispita'}
        message={`Da li ste sigurni da želite obrisati parcijalni ispit ${partialExam.name}? Svi rezultati će biti obrisani. Ovu akciju nije moguće povratiti.`}
        onConfirm={() => {
          deletePartialExam({ partialExamID: partialExam.id });
          onDeletePartialExamClose();
        }}
        confirmText={'Siguran sam za gubitak podataka'}
      />
      <GridItem>
        <VStack alignItems={'center'} spacing='5px'>
          <chakra.h2 fontSize='lg' fontWeight='700'>
            {partialExam.name}
          </chakra.h2>
          <Stack w={'100%'} direction={'row'} justify={'center'} spacing={1}>
            <Menu>
              <MenuButton
                color={'blue.300'}
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon />}
                width={'25px'}
                height={'25px'}
              />
              <MenuList>
                <MenuItem
                  icon={<EditIcon />}
                  onClick={() => {
                    onEditPartialExamOpen();
                  }}
                >
                  Uredi parcijalni ispit
                </MenuItem>
                <MenuItem
                  icon={<DeleteIcon />}
                  onClick={() => {
                    onDeletePartialExamOpen();
                  }}
                >
                  Izbriši parcijalni ispit
                </MenuItem>
              </MenuList>
            </Menu>
            <BlueButton
              fontSize='xs'
              height='25px'
              width='130px'
              buttonText='Otvori parcijalni ispit'
              onClick={() => {
                //navigate(`${activity.id}`);
                window.open(
                  `/profesori/ispiti/parcijalni-ispit/${partialExam.id}`,
                  '_blank',
                  'noreferrer'
                );
              }}
            />
          </Stack>
          <Grid
            pt={5}
            templateColumns={{
              base: 'repeat(5, 1fr)',
              lg: 'repeat(5, 1fr)',
            }}
            gap={{ base: '1', md: '5' }}
          >
            <GridItem colSpan={3}>
              <VStack align={{ base: 'start', md: 'end' }}>
                <VStack align={'start'} spacing={0}>
                  <HStack>
                    <Text fontSize={'xs'}>Ime ispita:</Text>
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      {partialExam.name}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'xs'}>Tip ispita:</Text>
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      {partialExam.type}
                    </Text>
                  </HStack>
                  <HStack>
                    {partialExam.conditionPartialExamName ? (
                      <>
                        <Text fontSize={'xs'}>Uslov Ispita:</Text>
                        <Text fontSize={'xs'} fontWeight={'bold'}>
                          {partialExam.conditionPartialExamName}
                        </Text>
                      </>
                    ) : (
                      <Text fontSize={'xs'}>Nema uslovnog ispita</Text>
                    )}
                  </HStack>
                  <Tooltip
                    label={
                      partialExam.signatureNeeded
                        ? 'Potreban je potpis za izlazak na ispit'
                        : 'Potpis nije potreban za izlazak na ispit'
                    }
                  >
                    <HStack>
                      <Text fontSize={'xs'}>Potreban potpis:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {partialExam.signatureNeeded ? 'DA' : 'NE'}
                      </Text>
                    </HStack>
                  </Tooltip>
                </VStack>
              </VStack>
            </GridItem>
            <GridItem colSpan={2}>
              <VStack align={'end'}>
                <VStack align={'start'} spacing={0}>
                  <Tooltip
                    label={
                      partialExam.visible
                        ? 'Ispit je prikazan kod studenata'
                        : 'Ispit nije prikazan studentima'
                    }
                  >
                    <HStack>
                      <Text fontSize={'xs'}>Skriven:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {partialExam.visible ? 'NE' : 'DA'}
                      </Text>
                    </HStack>
                  </Tooltip>
                  <HStack>
                    <Text fontSize={'xs'}>Broj bodova:</Text>
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      {partialExam.maxScore}
                    </Text>
                  </HStack>

                  <HStack>
                    <Text fontSize={'xs'}>Bodovi za prolaz:</Text>
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      {partialExam.minPassingScore}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'xs'}>Težinski faktor:</Text>
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      {partialExam.difficultyFactor}
                    </Text>
                  </HStack>
                </VStack>
              </VStack>
            </GridItem>
          </Grid>
        </VStack>
      </GridItem>
    </>
  );
};

type Props = {
  exam: ExamsPreview;
  examScheduleID: number;
};

export const ExamsPreviewComp = ({ exam, examScheduleID }: Props) => {
  const navigate = useNavigate();
  const profContext = useProf();

  const {
    isOpen: isCreatePartialExamOpen,
    onOpen: onCreatePartialExamOpen,
    onClose: onCreatePartialExamClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteExamOpen,
    onOpen: onDeleteExamOpen,
    onClose: onDeleteExamClose,
  } = useDisclosure();

  const {
    isOpen: isEditExamOpen,
    onOpen: onEditExamOpen,
    onClose: onEditExamClose,
  } = useDisclosure();

  const {
    isOpen: isRepeatExamOpen,
    onOpen: onRepeatExamOpen,
    onClose: onRepeatExamClose,
  } = useDisclosure();

  const examInfo = useGetExamInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const examStats = useGetExamStudentsStats(exam.id);

  const deleteExam = useDeleteExamMutation(examScheduleID, examInfo.data?.id);

  return (
    <Card
      w={'100%'}
      p={{ base: 2, md: 4 }}
      align={'center'}
      variant={'outline'}
      bg={useColorModeValue('white', 'gray.900')}
    >
      <EditExamModal examID={exam.id} isOpen={isEditExamOpen} onClose={onEditExamClose} />
      <CreatePartialExamModal
        examID={exam.id}
        examScheduleID={examScheduleID}
        partialExams={exam.partialExams}
        isOpen={isCreatePartialExamOpen}
        onClose={onCreatePartialExamClose}
      />
      <RepeatExamModal examID={exam.id} isOpen={isRepeatExamOpen} onClose={onRepeatExamClose} />
      <YesNoInputConfirmModal
        isOpen={isDeleteExamOpen}
        onClose={onDeleteExamClose}
        title={'Brisanje ispita'}
        message={`Da li ste sigurni da želite obrisati ispit ${exam.name}? Svi parcijalni ispiti zajedno s rezultatima će biti obrisani. Ovu akciju nije moguće povratiti.`}
        onConfirm={() => {
          deleteExam({ examID: exam.id });
          onDeleteExamClose();
        }}
        confirmText={'Siguran sam za gubitak podataka'}
      />
      <Box as={Container} maxW='100%' p={{ base: 1, md: 4 }}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={5}
        >
          <GridItem colSpan={1} alignSelf={'center'}>
            <VStack alignItems={{ base: 'center', md: 'start' }} spacing='5px'>
              <chakra.h2 fontSize='2xl' fontWeight='700'>
                {exam.name}
              </chakra.h2>
              <Stack
                w={'100%'}
                direction={'row'}
                justify={{ base: 'center', md: 'start' }}
                spacing={1}
              >
                <VStack>
                  <HStack>
                    <Menu size={'100px'}>
                      <MenuButton
                        color={'blue.300'}
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                        width={'25px'}
                        height={'25px'}
                      />
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={() => {
                            onEditExamOpen();
                          }}
                        >
                          Uredi ispit
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon />}
                          onClick={() => {
                            onDeleteExamOpen();
                          }}
                        >
                          Izbriši ispit
                        </MenuItem>
                        <MenuItem
                          icon={<FiRefreshCcw />}
                          onClick={() => {
                            onRepeatExamOpen();
                          }}
                        >
                          Ponovi ispit
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    {exam.partialExamNumber > 0 &&
                      exam.partialExams.length !== exam.partialExamNumber && (
                        <BlueButton
                          fontSize='xs'
                          height='25px'
                          width='100px'
                          buttonText='Dodaj parcijalu'
                          onClick={() => {
                            onCreatePartialExamOpen();
                          }}
                        />
                      )}
                    <BlueButton
                      fontSize='xs'
                      height='25px'
                      width='100px'
                      buttonText='Otvori ispit'
                      onClick={() => {
                        //navigate(`ispit/${exam.id}`);
                        window.open(`/profesori/ispiti/ispit/${exam.id}`, '_blank', 'noreferrer');
                      }}
                    />
                  </HStack>
                </VStack>
              </Stack>
            </VStack>
          </GridItem>
          <GridItem colSpan={2}>
            <Grid
              templateColumns={{
                base: 'repeat(5, 1fr)',
                md: 'repeat(5, 1fr)',
              }}
              gap={{ base: '1', md: '5' }}
            >
              <GridItem
                colSpan={{
                  base: 3,
                }}
              >
                <VStack align={{ base: 'start', md: 'center' }}>
                  <VStack align={'start'} spacing={0}>
                    <HStack>
                      <Text fontSize={'xs'}>Ime ispita:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.name}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={'xs'}>Tip ispita:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.type}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={'xs'}>
                        {exam.partialExamNumber > 0 ? 'Broj parcijala:' : 'Ispit nema parcijala'}{' '}
                      </Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.partialExamNumber > 0 ? exam.partialExamNumber : ''}
                      </Text>
                    </HStack>
                    <HStack>
                      {exam.conditionExamName ? (
                        <>
                          <Text fontSize={'xs'}>Uslov Ispita:</Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {exam.conditionExamName}
                          </Text>
                        </>
                      ) : (
                        <Text fontSize={'xs'}>Nema uslovnog ispita</Text>
                      )}
                    </HStack>
                    <Tooltip
                      label={
                        exam.visible
                          ? 'Ispit je prikazan kod studenata'
                          : 'Ispit nije prikazan studentima'
                      }
                    >
                      <HStack>
                        <Text fontSize={'xs'}>Skriven:</Text>
                        <Text fontSize={'xs'} fontWeight={'bold'}>
                          {exam.visible ? 'NE' : 'DA'}
                        </Text>
                      </HStack>
                    </Tooltip>
                    <HStack>
                      <Text fontSize={'xs'}>Broj bodova:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.maxScore}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={'xs'}>Bodovi za prolaz:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.minPassingScore}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={'xs'}>Težinski faktor:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.difficultyFactor}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              </GridItem>
              <GridItem
                colSpan={{
                  base: 2,
                }}
              >
                <VStack w={'2fr'} align={'center'}>
                  <VStack align={'start'} spacing={0}>
                    {/* <Tooltip
                      label={
                        exam.visible
                          ? 'Ispit je prikazan kod studenata'
                          : 'Ispit nije prikazan studentima'
                      }
                    >
                      <HStack>
                        <Text fontSize={'xs'}>Skriven:</Text>
                        <Text fontSize={'xs'} fontWeight={'bold'}>
                          {exam.visible ? 'NE' : 'DA'}
                        </Text>
                      </HStack>
                    </Tooltip>
                    <HStack>
                      <Text fontSize={'xs'}>Broj bodova:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.maxScore}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={'xs'}>Bodovi za prolaz:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.minPassingScore}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={'xs'}>Težinski faktor:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {exam.difficultyFactor}
                      </Text>
                    </HStack> */}

                    {examStats.isLoading ? (
                      <Center>
                        <Spinner />
                      </Center>
                    ) : examStats.data ? (
                      <>
                        <HStack>
                          <Text fontSize={'xs'}>Prijavljeni studenti:</Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {examStats.data.totalRegisteredStudents}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text fontSize={'xs'}>Prisutni studenti:</Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {examStats.data.attendedStudents}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text fontSize={'xs'}>Položili: </Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {examStats.data.passedStudents}
                          </Text>
                        </HStack>

                        <HStack>
                          <Text fontSize={'xs'}>Nisu položili: </Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {examStats.data.failedButAttendedStudents}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text fontSize={'xs'}>Nisu prisustvovali: </Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {examStats.data.notAttendedStudents}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text fontSize={'xs'}>Procenat prolaznosti:</Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {examStats.data.percentagePassed} %
                          </Text>
                        </HStack>
                        <HStack>
                          <Text fontSize={'xs'}>Prosjek bodova:</Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {examStats.data.averageScore}
                          </Text>
                        </HStack>
                      </>
                    ) : (
                      <Text>Statistika nije dostupna</Text>
                    )}
                  </VStack>
                </VStack>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        {exam.partialExamNumber > 0 && exam.partialExams && exam.partialExams.length > 0 && (
          <>
            <Divider my={10} />
            <Grid
              templateColumns={{
                base: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              gap={{ base: '8', sm: '12', md: '16' }}
            >
              {exam.partialExams.map((partialExam) => (
                <PartialExamComp
                  key={`partial-exam-preview-${partialExam.id}`}
                  partialExam={partialExam}
                  examScheduleID={examScheduleID}
                />
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Card>
  );
};
