import React from 'react';
import { Student } from '../../../../models/student';
import {
  Avatar,
  Card,
  Center,
  HStack,
  Img,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import useProf from '../../../../hooks/useProf.hooks';
import { useParams } from 'react-router-dom';
import {
  useGetExercisesInfo,
  useGetStudentsWithGroup,
  useGetStudentsWithNoGroup,
} from '../../../../services/queries';
import { BlueButton } from '../../../../components/general/BlueButton';
import { RedButton } from '../../../../components/general/RedButton';
import {
  useRemoveAllStudentsFromGroup,
  useRemoveStudentFromGroup,
} from '../../../../services/mutations';
import useAuth from '../../../../hooks/useAuth.hooks';
import { useState } from 'react';

interface LoadingStates {
  [key: number]: boolean;
}

export const StudentWithGroupTable = () => {
  const { groupID } = useParams();
  const authContext = useAuth();
  const profContext = useProf();
  const [filter, setFilter] = useState('');
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const toast = useToast();

  const exerciseInfoQuery = useGetExercisesInfo({
    yearID: profContext.selectedAcademicYearID,
    subjectID: profContext.selectedSubjectID,
  });

  const students = useGetStudentsWithGroup(Number(groupID));

  const removeStudent = useRemoveStudentFromGroup(
    profContext.selectedSubjectID,
    exerciseInfoQuery.data?.academicYearID,
    Number(groupID)
  );

  const removeAllStudents = useRemoveAllStudentsFromGroup(
    profContext.selectedSubjectID,
    exerciseInfoQuery.data?.academicYearID,
    Number(groupID)
  );

  const handleRemoveStudent = (studentID: number) => {
    if (authContext.user?.role === 'PROFESSOR') {
      toast({
        position: 'top',
        title: 'Nemate ovlaštenje za ovu akciju',
        status: 'warning',
        duration: 3000,
      });
    } else {
      if (exerciseInfoQuery.data?.academicYearID && groupID && studentID) {
        setLoadingStates((prev) => ({ ...prev, [studentID]: true }));
        removeStudent({
          groupID: Number(groupID),
          studentID: studentID,
          academicYearID: exerciseInfoQuery.data?.academicYearID,
        })
          .catch((error) => {})
          .finally(() => {
            setFilter('');
            setTimeout(() => {
              setLoadingStates((prev) => ({ ...prev, [studentID]: false }));
            }, 2000);
          });
      }
    }
  };

  const handleRemoveAllStudents = () => {
    if (authContext.user?.role === 'PROFESSOR') {
      toast({
        position: 'top',
        title: 'Nemate ovlaštenje za ovu akciju',
        status: 'warning',
        duration: 3000,
      });
    } else {
      removeAllStudents(Number(groupID))
        .catch((error) => {})
        .finally(() => {
          setFilter('');
        });
    }
  };

  const filteredStudents = students.data?.filter(
    (student) =>
      student.indexNumber.includes(filter) ||
      student.lastName.toLowerCase().includes(filter.toLowerCase()) ||
      student.firstName.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <HStack w='100%' h='45' justify={'center'} align={'center'}>
        <RedButton
          buttonText='Izbaci sve studente'
          onClick={() => {
            handleRemoveAllStudents();
          }}
        />
        <Input
          w='220px'
          placeholder='Pretraži studente'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </HStack>
      <TableContainer mt={'3rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>Izbaci</Th>
              <Th textAlign='center'>Ime i Prezime</Th>
              <Th textAlign='center'>B.Indexa</Th>
              <Th textAlign='center'>Slika</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={4}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : filteredStudents && filteredStudents.length > 0 ? (
              filteredStudents.map((student) => (
                <Tr key={student.id}>
                  <Td textAlign='center'>
                    <RedButton
                      width='50px'
                      height='25px'
                      buttonText='izbaci'
                      onClick={() => {
                        handleRemoveStudent(student.id);
                      }}
                      isLoading={loadingStates[student.id]}
                    />
                  </Td>
                  <Td textAlign='center'>
                    {student.firstName} {student.lastName}
                  </Td>
                  <Td textAlign='center'>{student.indexNumber}</Td>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={student.profileImage} />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Center>Nema studenata u grupi</Center>
                </Td>
              </Tr>
            )}
          </Tbody>
          {students.data && students.data.length > 10 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>Izbaci</Th>
                <Th textAlign='center'>Ime i Prezime</Th>
                <Th textAlign='center'>B.Indexa</Th>
                <Th textAlign='center'>Slika</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
