import {
  HStack,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { examsCR } from '../../../../models/student-complete-report';
import { Fragment } from 'react';

type Props = {
  exams: examsCR | null;
};

export const ExamsInfo = ({ exams }: Props) => {
  return (
    <VStack justify={'center'}>
      <HStack justify={'center'}>
        <Heading fontSize={'lg'}>Ispiti</Heading>
      </HStack>

      {exams ? (
        <>
          <HStack spacing={4} justify={'flex-end'} w={'90%'}>
            <Text fontSize={'md'}>| Bodovi: {exams.scoreDF} |</Text>
          </HStack>
          <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
            <Table variant='striped' colorScheme='facebook' size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign='center'>Ispit</Th>
                  <Th textAlign='center'>Parcijala</Th>
                  <Th textAlign='center'>Bodovi</Th>
                </Tr>
              </Thead>
              <Tbody>
                {exams.exams.map((exam, index) => (
                  <Fragment key={`exam-${index}`}>
                    <Tr>
                      <Td textAlign='center'>{exam.examName}</Td>
                      <Td textAlign='center'></Td>
                      <Td textAlign='center'>
                        {exam.studentScore} / {exam.maxScore}
                      </Td>
                    </Tr>
                    {exam.partialExams.map((partialExam, index) => (
                      <Tr key={`partial-${index}`}>
                        <Td textAlign='center'></Td>
                        <Td textAlign='center'>{partialExam.partialExamName}</Td>
                        <Td textAlign='center'>
                          {partialExam.studentScore} / {partialExam.maxScore}
                        </Td>
                      </Tr>
                    ))}
                  </Fragment>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Text>Student nema potvrđene rezultate</Text>
      )}
    </VStack>
  );
};
