import {
  Button,
  HStack,
  Heading,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { BlueButton } from '../../../../components/general/BlueButton';
import useProf from '../../../../hooks/useProf.hooks';
import { AcademicYear } from '../../../../models/subject';
import {
  useGetAcademicYears,
  useGetExercisesInfo,
  useGetSubjectActivitiesInfo,
} from '../../../../services/queries';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth.hooks';
import { useState } from 'react';

type Props = {};

export const SubjectActivitesGroupInfo = (props: Props) => {
  const profContext = useProf();
  const authContext = useAuth();
  const years = useGetAcademicYears(profContext.selectedSubjectID);
  const navigate = useNavigate();
  const subjectActivitiesQuery = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w={{ base: '90vw', md: '85%', lg: '700px' }}
      justify='space-around'
      pb={10}
    >
      {/*  <CreateGroupModal isOpen={isCreateGroupOpen} onClose={onCreateGroupClose} /> */}
      <VStack align={'start'} spacing={0}>
        {years.isLoading ? (
          <HStack w={'100%'} justify={'center'}>
            <Spinner />
          </HStack>
        ) : (
          years.data &&
          years.data.length > 0 && (
            <Select
              w={{ base: '95%', sm: '263px', md: '263px' }}
              id={'AcademicYearSelector'}
              colorScheme='blue'
              onChange={handleOnChangeAcademicYear}
            >
              {years.data.map((year: AcademicYear) => (
                <option key={year.id} value={year.id}>
                  {year.currentYear ? `${year.year} - tekuća` : year.year}
                </option>
              ))}
            </Select>
          )
        )}

        {subjectActivitiesQuery.isLoading ? (
          <HStack w='263px' h='132px' justify={'center'}>
            <Spinner />
          </HStack>
        ) : subjectActivitiesQuery.data ? (
          <>
            <HStack pt={'10px'} align={'center'}>
              <Text>Težinski faktor po grupi: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {subjectActivitiesQuery.data.difficultyFactorGroup}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Broj Grupa: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {subjectActivitiesQuery.data.groups.length}
              </Text>
            </HStack>
          </>
        ) : (
          <VStack align={'start'}>
            <Text>Kreirana je nova akademska godina.</Text>
            <Text>Da li želite mjenjati podatke o vježbama?</Text>
            <Button w={'100%'} bgColor={'blue.600'}>
              Uvezi iz prošle godine
            </Button>
            <Button w={'100%'} bgColor={'blue.600'}>
              Unesi nove podatke
            </Button>
          </VStack>
        )}
      </VStack>
      <Stack pt={{ base: 5, md: 0 }} direction={{ base: 'row', md: 'column' }} justify={'center'}>
        <BlueButton
          buttonText='Dodaj Grupu'
          onClick={() => {
            navigate('/profesori/vjezbe');
          }}
        />
      </Stack>
      subj
    </Stack>
  );
};
