import {
  Flex,
  HStack,
  Heading,
  Select,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import useProf from '../../../hooks/useProf.hooks';
import { useGetAcademicYears } from '../../../services/queries';
import { AcademicYear } from '../../../models/subject';
import { LectureAttendancesStatTable } from './comp/LectureAttendanceStatTable';
import { ExerciseAttendancesStatTable } from './comp/ExerciseAttendanceStatTable';
import { BlueButton } from '../../../components/general/BlueButton';

type Props = {};

export const AttendanceStatsPage = (props: Props) => {
  const navigate = useNavigate();
  const profContext = useProf();
  const years = useGetAcademicYears(profContext.selectedSubjectID);

  const handleBackClick = () => {
    navigate('/profesori/studenti');
  };

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      justify={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
      pos={'relative'}
    >
      <Stack pos={'absolute'} top={'20px'} left={{ base: 5 }}>
        <ArrowBackButton onClick={handleBackClick} />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Pregled stanja prisustva
        </Heading>
      </HStack>
      <Tabs w={'100%'} isFitted>
        <TabList>
          <Tab fontWeight={'700'}>Predavanja</Tab>
          <Tab fontWeight={'700'}>Vježbe</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={3} mx={'auto'} w={'95%'} justify={'center'} pt={4}>
              <Stack
                spacing={5}
                w={{ base: '95%', md: '95%', lg: '50%' }}
                align={'center'}
                justify={'center'}
                mb={4}
                direction={{ base: 'column', md: 'row' }}
              >
                {years.isLoading ? (
                  <HStack w={'100%'} justify={'center'}>
                    <Spinner />
                  </HStack>
                ) : (
                  years.data &&
                  years.data.length > 0 && (
                    <Select
                      id={'AcademicYearSelector'}
                      colorScheme='blue'
                      onChange={handleOnChangeAcademicYear}
                    >
                      {years.data.map((year: AcademicYear) => (
                        <option key={year.id} value={year.id}>
                          {year.currentYear ? `${year.year} - tekuća` : year.year}
                        </option>
                      ))}
                    </Select>
                  )
                )}
                <BlueButton
                  fontSize='sm'
                  height='30px'
                  width='150px'
                  buttonText='Podjela potpisa'
                  onClick={() => {
                    navigate(`/profesori/studenti/pregled-stanja-prisustva/podjela-potpisa`);
                  }}
                />
              </Stack>
              <LectureAttendancesStatTable />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={3} mx={'auto'} w={'95%'} justify={'center'} pt={4}>
              <Stack
                spacing={5}
                w={{ base: '95%', md: '95%', lg: '50%' }}
                align={'center'}
                justify={'center'}
                direction={{ base: 'column', md: 'row' }}
              >
                {years.isLoading ? (
                  <HStack w={'100%'} justify={'center'}>
                    <Spinner />
                  </HStack>
                ) : (
                  years.data &&
                  years.data.length > 0 && (
                    <Select
                      id={'AcademicYearSelector'}
                      colorScheme='blue'
                      onChange={handleOnChangeAcademicYear}
                    >
                      {years.data.map((year: AcademicYear) => (
                        <option key={year.id} value={year.id}>
                          {year.currentYear ? `${year.year} - tekuća` : year.year}
                        </option>
                      ))}
                    </Select>
                  )
                )}
                <BlueButton
                  fontSize='sm'
                  height='30px'
                  width='150px'
                  buttonText='Podjela potpisa'
                  onClick={() => {
                    navigate(`/profesori/studenti/pregled-stanja-prisustva/podjela-potpisa`);
                  }}
                />
              </Stack>
              <ExerciseAttendancesStatTable />
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
