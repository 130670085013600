import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import {
  addStudentToExam,
  addStudentToPartialExam,
  addStudentsExcelTableMutation,
  addStudentsToSubjectActivity,
  adminCreateAdmin,
  adminCreateAssistant,
  adminCreateFaculty,
  adminCreateProfessor,
  adminCreateStudent,
  adminCreateStudyProgram,
  adminCreateUniversity,
  adminEditStudent,
  adminResetStudentPasswordMutation,
  adminToggleAdmin,
  adminToggleAssistant,
  adminToggleProfessor,
  approveAssistantMutation,
  approveProfessorMutation,
  approveStudentOnSubjectMutation,
  assignAllStudentsToGroupMutation,
  assignStudentToGroupMutation,
  assistCreatePost,
  assistDeletePost,
  assistEditPost,
  assistantChangePasswordMutation,
  cancleExamRegistrationMutation,
  canclePartialExamRegistrationMutation,
  changeProfileImage,
  confirmStudentExamScheduleResults,
  createExamMutation,
  createExamScheduleMutation,
  createExerciseMutation,
  createGroupMutation,
  createLectureMutation,
  createPartialExamMutation,
  createStudentsExcelTableMutation,
  createSubjectActivityMutation,
  deleteAssistantMutation,
  deleteExam,
  deleteExamSchedule,
  deleteExerciseMutation,
  deleteGroupMutation,
  deleteLectureMutation,
  deletePartialExam,
  deleteProfessorMutation,
  deleteStudentExamRegistration,
  deleteStudentExamScheduleResults,
  deleteStudentFromSubjectMutation,
  deleteStudentPartialExamRegistration,
  deleteSubjectActivity,
  deleteSubjectMutation,
  editCurrentExercisesInfo,
  editCurrentLecturesInfo,
  editExamDatesInfoMutation,
  editExamMainInfoMutation,
  editExamScheduleMutation,
  editExamScoreInfoMutation,
  editMainSubjectInfo,
  editPartialExamDatesInfoMutation,
  editPartialExamMainInfoMutation,
  editPartialExamScoreInfoMutation,
  editSubjectActivityMutation,
  editSubjectDifficultyFactors,
  examRegistrationMutation,
  getStudentsExamPreviews,
  joinSubjectMutation,
  nullifyExamResultsMutation,
  nullifyPartialExamResultsMutation,
  partialExamRegistrationMutation,
  profCreatePost,
  profDeletePost,
  profEditPost,
  professorChangePasswordMutation,
  rejectStudentOnSubjectMutation,
  removeAllStudentsFromGroupMutation,
  removeStudentFromGroupMutation,
  repeatExam,
  sendSingleExerciseReportToEmail,
  sendSingleLectureReportToEmail,
  setAllStudentsNotPresentExerciseMutation,
  setAllStudentsNotPresentMutation,
  setAllStudentsPresentExerciseMutation,
  setAllStudentsPresentMutation,
  setExamGroupDatePlaceMutation,
  setExamStudentScore,
  setPartExamGroupDatePlaceMutation,
  setPartialExamStudentScore,
  setStudentAdditionalPoints,
  setSubjectActivityStudentScore,
  staffAddStudent,
  staffCreateStudent,
  staffRegisterStudentExamMutation,
  studentAttendExerciseMutation,
  studentAttendLectureMutation,
  studentChangePasswordMutation,
  studentJoinSubjectMutation,
  studentsOnExerciseMutation,
  studentsOnSubjectMutation,
  toggleAsistantOnGroupMutation,
  toggleExerciseScanner,
  toggleLectureScanner,
  toggleStudentExamAttendance,
  toggleStudentExamNullification,
  toggleStudentExercisePresence,
  toggleStudentHasCompletedSubject,
  toggleStudentPartialExamAttendance,
  toggleStudentPartialExamNullification,
  toggleStudentPresence,
  toggleStudentRepeater,
  toggleStudentSignature,
  updateAllDatePlaceMutation,
  updateAllPartialExamDatePlaceMutation,
  updateExerciseMutation,
  updateLectureMutation,
} from './api';
import useProf from '../hooks/useProf.hooks';

//              **** SUBJECT ****            //
export function useDeleteSubjectMutation() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteSubject } = useMutation({
    mutationFn: deleteSubjectMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getSubjects'],
      });

      toast({
        position: 'top',
        title: 'Predmet je uspješno obrisan.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return deleteSubject;
}

export function useEditSubjectMainInfo(subjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: editSubjectInfo } = useMutation({
    mutationFn: editMainSubjectInfo,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getSelectedSubjectInfo'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getSubjects'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste promjenili osnovne informacije o predmetu.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return editSubjectInfo;
}

export function useEditDifficultyFactors(subjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: editDF } = useMutation({
    mutationFn: editSubjectDifficultyFactors,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getSubjectDifficultyFactors'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste promjenili težinske faktore.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return editDF;
}

export function useEditCurrentLecturesInfo(subjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: editLecturesInfo } = useMutation({
    mutationFn: editCurrentLecturesInfo,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getCurrentLecturesInfo'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste promjenili podatke o predavanjima.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return editLecturesInfo;
}

export function useEditCurrentExercisesInfo(subjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: editExercisesInfo } = useMutation({
    mutationFn: editCurrentExercisesInfo,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getCurrentExercisesInfo'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste promjenili podatke o vježbama.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return editExercisesInfo;
}

export function useToggleStudentSignature(subjectID: number | null) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleSignature } = useMutation({
    mutationFn: toggleStudentSignature,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getSignatureStats'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 5000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return toggleSignature;
}

export function useSetStudentAdditionalPoints(
  subjectID: number | undefined,
  studentID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: setAdditionalPoints } = useMutation({
    mutationFn: setStudentAdditionalPoints,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [{ studentID, subjectID }, 'getStudentCompleteReport'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return setAdditionalPoints;
}

//              **** PROFESSORS ****            //
export function useDeleteProfessorMutation(selectedSubjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteProfessor } = useMutation({
    mutationFn: deleteProfessorMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getProfessorsOnSubject'],
      });
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getStaffWantsToJoinSubject'],
      });
      toast({
        position: 'top',
        title: 'Profesor je uspješno obrisan.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return deleteProfessor;
}

export function useDeleteAssistantMutation(selectedSubjectID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteAssistant } = useMutation({
    mutationFn: deleteAssistantMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getAssistantsOnSubject'],
      });
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getStaffWantsToJoinSubject'],
      });
      toast({
        position: 'top',
        title: 'Asistent je uspješno obrisan.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return deleteAssistant;
}

export function useJoinSubjectMutation() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: joinSubject } = useMutation({
    mutationFn: joinSubjectMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['getSubjects'],
      });
      toast({
        position: 'top',
        title: data?.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return joinSubject;
}

export function useApproveAssistantSubject(selectedSubjectID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: approveAssistant } = useMutation({
    mutationFn: approveAssistantMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getAssistantsOnSubject'],
      });
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getStaffWantsToJoinSubject'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste se odobrili asistenta na predmetu.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return approveAssistant;
}

export function useApproveProfessorSubject(selectedSubjectID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: approveProfessor } = useMutation({
    mutationFn: approveProfessorMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getProfessorsOnSubject'],
      });
      queryClient.invalidateQueries({
        queryKey: [selectedSubjectID, 'getStaffWantsToJoinSubject'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste se odobrili profesora na predmetu.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return approveProfessor;
}

export function useToggleAssistantOnGroupMutation(
  groupID: number | undefined,
  subjectID: number | undefined,

  academicYearID: number | undefined,
  exerciseInfoID: number | undefined
) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleAssistant } = useMutation({
    mutationFn: toggleAsistantOnGroupMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ groupID, subjectID }, 'getAssistantsBySubjectAndGroup'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ academicYearID, exerciseInfoID }, 'getExerciseGroups'],
      });

      toast({
        position: 'top',
        title: 'Uspješno je promjenjeno odobrenje za asistenta.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return toggleAssistant;
}

export function useProfessorChangePasswordMutation() {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: changePassword } = useMutation({
    mutationFn: professorChangePasswordMutation,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Uspješno ste promijenili šifru',
        status: 'success',
        duration: 3000,
      });
      //navigate('/prijava');
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return changePassword;
}

export function useAssistantChangePasswordMutation() {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: changePassword } = useMutation({
    mutationFn: assistantChangePasswordMutation,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Uspješno ste promijenili šifru',
        status: 'success',
        duration: 3000,
      });
      navigate('/prijava');
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return changePassword;
}

export function useAddStudentsExcelTableMutation() {
  const toast = useToast();

  const { mutateAsync: addStudentsTable } = useMutation({
    mutationFn: addStudentsExcelTableMutation,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Tabela je uspješno obrađena',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return addStudentsTable;
}

export function useChangeProfileImgMutation() {
  const toast = useToast();

  const { mutateAsync: changeProfileImg } = useMutation({
    mutationFn: changeProfileImage,
    onSuccess: () => {
      toast({
        position: 'top',
        title:
          'Vaša slika je uspješno promjenjena, može potrajati par minuta prije nego što vidite promjenu',
        status: 'success',
        duration: 5000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return changeProfileImg;
}

export function useToggleStudentRepeater(subjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleRepeater } = useMutation({
    mutationFn: toggleStudentRepeater,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getActiveStudentsOnSubject'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleRepeater;
}

export function useToggleStudentHasCompletedSubject(
  subjectID: number | undefined,
  studentID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: setAdditionalPoints } = useMutation({
    mutationFn: toggleStudentHasCompletedSubject,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [{ studentID, subjectID }, 'getStudentCompleteReport'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return setAdditionalPoints;
}

//              **** LECTURES ****            //
export function useCreateLecturesMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createLecture } = useMutation({
    mutationFn: createLectureMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLectures'],
      });
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLecturesInfo'],
      });
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLecturesAttendanceStats'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste dodali novo predavanje',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => createLecture(lectureData);
}

export function useUpdateLectureMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: updateLecture } = useMutation({
    mutationFn: updateLectureMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLectures'],
      });
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLecturesInfo'],
      });

      toast({
        position: 'top',
        title: 'Promjene su uspješno sačuvane.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return updateLecture;
}

export function useDeleteLectureMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteAssistant } = useMutation({
    mutationFn: deleteLectureMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLectures'],
      });
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLecturesInfo'],
      });
      toast({
        position: 'top',
        title: 'Predavanje je uspješno izbrisano',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return deleteAssistant;
}

export function useSetStudentsOnLecturesMutation(lectureID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: setStudentsOnLecture } = useMutation({
    mutationFn: studentsOnSubjectMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getLectureAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getSingleLecturesInfo'],
      });
      toast({
        position: 'top',
        title: 'Svi studenti su uspješno dodani',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return setStudentsOnLecture;
}

export function useSetAllStudentsPresent(lectureID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: setAllStudentsPresent } = useMutation({
    mutationFn: setAllStudentsPresentMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getLectureAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getSingleLecturesInfo'],
      });
      toast({
        position: 'top',
        title: 'Svi studenti su uspješno označeni kao prisutni.',
        status: 'success',
        duration: 2000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return setAllStudentsPresent;
}

export function useSetAllStudentsNotPresent(lectureID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: setAllStudentsNotPresent } = useMutation({
    mutationFn: setAllStudentsNotPresentMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getLectureAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getSingleLecturesInfo'],
      });
      toast({
        position: 'top',
        title: 'Svi studenti su uspješno označeni kao odsutni.',
        status: 'success',
        duration: 2000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return setAllStudentsNotPresent;
}

export function useToggleStudentPresence(lectureID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleStudent } = useMutation({
    mutationFn: toggleStudentPresence,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getLectureAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [lectureID, 'getSingleLecturesInfo'],
      });
      toast({
        position: 'top',
        title: 'Uspješna promjena stanja studenta.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleStudent;
}

export function useToggleLectureScanner(subjectID: number, yearID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleLectureScan } = useMutation({
    mutationFn: toggleLectureScanner,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getLectures'],
      });
      toast({
        position: 'top',
        title: 'Uspješna promjena stanja skeniranja.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleLectureScan;
}

export function useSendSingleLectureReportEmail() {
  const toast = useToast();

  const { mutateAsync: sendLectureReport } = useMutation({
    mutationFn: sendSingleLectureReportToEmail,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Izvještaj je uspješno poslan na vaš Email.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return sendLectureReport;
}

//              **** EXERCISES ****            //

export function useCreateGroupMutation(
  exerciseInfoID: number | null,
  academicYearID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createGroup } = useMutation({
    mutationFn: createGroupMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ academicYearID, exerciseInfoID }, 'getExerciseGroups'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste dodali novu grupu',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createGroup;
}

export function useDeleteGroupMutation(
  academicYearID: number | undefined,
  exerciseInfoID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteGroup } = useMutation({
    mutationFn: deleteGroupMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ academicYearID, exerciseInfoID }, 'getExerciseGroups'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste izbrisali grupu.',
        status: 'success',
        duration: 3000,
      });
    },

    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return deleteGroup;
}

export function useAssignStudentToGroup(
  subjectID: number | null,
  academicYearID: number | null | undefined,
  groupID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: assignStudent } = useMutation({
    mutationFn: assignStudentToGroupMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ academicYearID, subjectID }, 'getStudentsWithNoGroup'],
      });
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getStudentsWithGroup'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste ubacili studenta u grupu.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return assignStudent;
}

export function useRemoveStudentFromGroup(
  subjectID: number | null,
  academicYearID: number | null | undefined,
  groupID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: removeStudent } = useMutation({
    mutationFn: removeStudentFromGroupMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ academicYearID, subjectID }, 'getStudentsWithNoGroup'],
      });
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getStudentsWithGroup'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste izbacili studenta iz grupe.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return removeStudent;
}

export function useUpdateExerciseMutation(groupID: number | null) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: updateExercise } = useMutation({
    mutationFn: updateExerciseMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercisesPerGroupInfo'],
      });
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercises'],
      });

      toast({
        position: 'top',
        title: 'Promjene su uspješno sačuvane.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return updateExercise;
}

export function useAssignAllStudentsToGroup(
  subjectID: number | null,
  academicYearID: number | null | undefined,
  groupID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: assignStudent } = useMutation({
    mutationFn: assignAllStudentsToGroupMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ academicYearID, subjectID }, 'getStudentsWithNoGroup'],
      });
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getStudentsWithGroup'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste ubacili sve studente u grupu.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return assignStudent;
}

export function useRemoveAllStudentsFromGroup(
  subjectID: number | null,
  academicYearID: number | null | undefined,
  groupID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: removeStudent } = useMutation({
    mutationFn: removeAllStudentsFromGroupMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ academicYearID, subjectID }, 'getStudentsWithNoGroup'],
      });
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getStudentsWithGroup'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste ubacili sve studente u grupu.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return removeStudent;
}

export function useCreateExerciseMutation(groupID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createExercise } = useMutation({
    mutationFn: createExerciseMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercisesPerGroupInfo'],
      });

      queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercises'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste dodali nove vježbe',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => createExercise(lectureData);
}

export function useDeleteExerciseMutation(groupID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteAssistant } = useMutation({
    mutationFn: deleteExerciseMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercisesPerGroupInfo'],
      });

      queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercises'],
      });
      toast({
        position: 'top',
        title: 'Vježbe su uspješno izbrisane',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return deleteAssistant;
}

export function useSetStudentsOnExerciseMutation(exerciseID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: setStudentsOnExercise } = useMutation({
    mutationFn: studentsOnExerciseMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getExerciseAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getSingleExerciseInfo'],
      });
      toast({
        position: 'top',
        title: 'Svi studenti su uspješno dodani',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return setStudentsOnExercise;
}

export function useSetAllStudentsPresentExerciseMutation(exerciseID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: setAllStudentsPresent } = useMutation({
    mutationFn: setAllStudentsPresentExerciseMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getExerciseAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getSingleExerciseInfo'],
      });
      toast({
        position: 'top',
        title: 'Svi studenti su uspješno označeni kao prisutni.',
        status: 'success',
        duration: 2000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return setAllStudentsPresent;
}

export function useSetAllStudentsNotPresentExerciseMutation(exerciseID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: setAllStudentsNotPresent } = useMutation({
    mutationFn: setAllStudentsNotPresentExerciseMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getExerciseAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getSingleExerciseInfo'],
      });
      toast({
        position: 'top',
        title: 'Svi studenti su uspješno označeni kao odsutni.',
        status: 'success',
        duration: 2000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return setAllStudentsNotPresent;
}

export function useToggleStudentExercisePresence(exerciseID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleStudent } = useMutation({
    mutationFn: toggleStudentExercisePresence,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getExerciseAttendances'],
      });
      queryClient.invalidateQueries({
        queryKey: [exerciseID, 'getSingleExerciseInfo'],
      });
      toast({
        position: 'top',
        title: 'Uspješna promjena stanja studenta.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleStudent;
}

export function useToggleExerciseScanner(groupID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleExerciseScan } = useMutation({
    mutationFn: toggleExerciseScanner,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercises'],
      });
      toast({
        position: 'top',
        title: 'Uspješna promjena stanja skeniranja.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleExerciseScan;
}

export function useSendSingleExerciseReportEmail() {
  const toast = useToast();

  const { mutateAsync: sendExerciseReport } = useMutation({
    mutationFn: sendSingleExerciseReportToEmail,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Izvještaj je uspješno poslan na vaš Email.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return sendExerciseReport;
}

//              **** STUDENTS ****            //

export function useStaffCreateStudentMutation() {
  const toast = useToast();

  const { mutateAsync: createStudent } = useMutation({
    mutationFn: staffCreateStudent,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novog studenta.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createStudent;
}

export function useStaffAddStudentMutation() {
  const toast = useToast();

  const { mutateAsync: addStudent } = useMutation({
    mutationFn: staffAddStudent,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Uspješno ste dodali studenta na predmet.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return addStudent;
}

export function useStudentJoinSubject() {
  const toast = useToast();

  const { mutateAsync: joinStudent } = useMutation({
    mutationFn: studentJoinSubjectMutation,
    onSuccess: (res: any) => {
      /*  queryClient.invalidateQueries({
        queryKey: [groupID, 'getExercisesPerGroupInfo'],
      }); */

      toast({
        position: 'top',
        title: res.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return joinStudent;
}

export function useStudentAttendLecture(subjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: joinLecture } = useMutation({
    mutationFn: studentAttendLectureMutation,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsLectureAttendanceInfo'],
      });
      toast({
        position: 'top',
        title: 'Uspješan potpis',
        status: 'success',
        duration: 20000,
        isClosable: true,
        description: res.message,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: 'Neuspješan potpis',
        status: 'error',
        duration: 20000,
        isClosable: true,
        description: error.data.message,
      });
    },
  });

  return joinLecture;
}

export function useStudentAttendExercise(subjectID: number) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: joinExercise } = useMutation({
    mutationFn: studentAttendExerciseMutation,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsExerciseAttendanceInfo'],
      });
      toast({
        position: 'top',
        title: 'Uspješan potpis',
        status: 'success',
        duration: 20000,
        isClosable: true,
        description: res.message,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: 'Neuspješan potpis',
        status: 'error',
        duration: 20000,
        isClosable: true,
        description: error.data.message,
      });
    },
  });

  return joinExercise;
}

export function useStudentChangePasswordMutation() {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: changePassword } = useMutation({
    mutationFn: studentChangePasswordMutation,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Uspješno ste promijenili šifru',
        status: 'success',
        duration: 3000,
      });
      navigate('/prijava-student');
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return changePassword;
}

export function useApproveStudentToSubjectMutation(subjectID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: approveStudent } = useMutation({
    mutationFn: approveStudentOnSubjectMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsWantToJoinsubject'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste odobrili studenta na predmet',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });
  return approveStudent;
}

export function useRejectStudentToSubjectMutation(subjectID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: rejectStudent } = useMutation({
    mutationFn: rejectStudentOnSubjectMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsWantToJoinsubject'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste odbili studenta sa predmeta',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });
  return rejectStudent;
}

export function useDeleteStudentFromSubjectMutation(subjectID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteStudent } = useMutation({
    mutationFn: deleteStudentFromSubjectMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getActiveStudentsOnSubject'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste Uklonili studenta iz predmeta',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });
  return deleteStudent;
}

export function useStudentExamRegistration(subjectID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: examRegistration } = useMutation({
    mutationFn: examRegistrationMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsExamPreview'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste se prijavili na ispit.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => examRegistration(examData);
}

export function useStudentPartialExamRegistration(subjectID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: examRegistration } = useMutation({
    mutationFn: partialExamRegistrationMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsExamPreview'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste se prijavili na ispit.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => examRegistration(examData);
}

export function useCancleExamRegistration(subjectID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: examRegistration } = useMutation({
    mutationFn: cancleExamRegistrationMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsExamPreview'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => examRegistration(examData);
}

export function useStudentNullifyExamResults(subjectID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: examRegistration } = useMutation({
    mutationFn: nullifyExamResultsMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsExamPreview'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => examRegistration(examData);
}

export function useCanclePartialExamRegistration(subjectID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: examRegistration } = useMutation({
    mutationFn: canclePartialExamRegistrationMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsExamPreview'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => examRegistration(examData);
}

export function useStudentNullifyPartialExamResults(subjectID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: examRegistration } = useMutation({
    mutationFn: nullifyPartialExamResultsMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectID, 'getStudentsExamPreview'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => examRegistration(examData);
}
//              **** ADMINS ****            //

export function useAdminCreateProfessorMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createProfessor } = useMutation({
    mutationFn: adminCreateProfessor,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getProfessors'],
      });

      toast({
        position: 'top',
        title:
          'Uspješno ste kreirali novog profesora. Kreirani profesor će dobiti email sa kredencijalima za prijavu.',
        status: 'success',
        duration: 5000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createProfessor;
}

export function useAdminCreateAssistantMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createAssistant } = useMutation({
    mutationFn: adminCreateAssistant,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getAssistants'],
      });

      toast({
        position: 'top',
        title:
          'Uspješno ste kreirali novog asistenta. Kreirani asistent će dobiti email sa kredencijalima za prijavu.',
        status: 'success',
        duration: 5000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createAssistant;
}

export function useAdminCreateUniversityMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createUniversity } = useMutation({
    mutationFn: adminCreateUniversity,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getFacultysWithPrograms'],
      });

      queryClient.invalidateQueries({
        queryKey: ['AllUniversities'],
      });

      queryClient.invalidateQueries({
        queryKey: ['getAllOrganizations'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novi Univerzitet.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createUniversity;
}

export function useAdminCreateFacultyMutation(universityID: number | undefined) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createFaculty } = useMutation({
    mutationFn: adminCreateFaculty,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getFacultysWithPrograms'],
      });
      queryClient.invalidateQueries({
        queryKey: [universityID, 'AllFacultys'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getAllOrganizations'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novi fakultet.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createFaculty;
}

export function useAdminCreateStudyProgramMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createStudyProgram } = useMutation({
    mutationFn: adminCreateStudyProgram,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getFacultysWithPrograms'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getAllOrganizations'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novi studijski program.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createStudyProgram;
}

export function useAdminCreateStudentMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createStudent } = useMutation({
    mutationFn: adminCreateStudent,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getAllStudentsAdmin'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novog studenta.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createStudent;
}

export function useCreateStudentsExcelTableMutation() {
  const toast = useToast();

  const { mutateAsync: addStudentsTable } = useMutation({
    mutationFn: createStudentsExcelTableMutation,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Tabela je uspješno obrađena',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      console.log(error);
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return addStudentsTable;
}

export function useAdminEditStudentMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createStudent } = useMutation({
    mutationFn: adminEditStudent,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getAllStudentsAdmin'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste uredili studenta.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createStudent;
}

export function useAdminCreateAdminMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: createAdmin } = useMutation({
    mutationFn: adminCreateAdmin,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUniversitiesWithAdmins'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novog admina.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return createAdmin;
}

export function useToggleProfessorsMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleProfessor } = useMutation({
    mutationFn: adminToggleProfessor,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getProfessors'],
      });
      toast({
        position: 'top',
        title: 'Uspješna promjena stanja profesora.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleProfessor;
}

export function useToggleAssistantsMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleAssistant } = useMutation({
    mutationFn: adminToggleAssistant,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getAssistants'],
      });
      toast({
        position: 'top',
        title: 'Uspješna promjena stanja asistenta.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleAssistant;
}

export function useToggleAdminMutation() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: toggleAdmin } = useMutation({
    mutationFn: adminToggleAdmin,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUniversitiesWithAdmins'],
      });
      toast({
        position: 'top',
        title: 'Uspješna promjena stanja asistenta.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return toggleAdmin;
}

export function useAdminResetStudentPasswordMutation() {
  const toast = useToast();

  const { mutateAsync: resetPassword } = useMutation({
    mutationFn: adminResetStudentPasswordMutation,
    onSuccess: () => {
      toast({
        position: 'top',
        title: 'Uspješna ste resetovali šifru studentu.',
        status: 'success',
        duration: 1000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 2000,
      });
    },
  });

  return resetPassword;
}

//              **** POSTS ****            //

export function useProfCreatePostMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createPost } = useMutation({
    mutationFn: profCreatePost,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectPostsProf'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste objavili novu objavu',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => createPost(lectureData);
}

export function useProfEditPostMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editPost } = useMutation({
    mutationFn: profEditPost,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectPostsProf'],
      });
      toast({
        position: 'top',
        title: 'Promjene su uspješno sačuvane.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => editPost(lectureData);
}

export function useProfDeletePostMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deletePost } = useMutation({
    mutationFn: profDeletePost,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectPostsProf'],
      });
      toast({
        position: 'top',
        title: 'Objava je uspjšno izbrisana.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => deletePost(lectureData);
}

export function useAssistCreatePostMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createPost } = useMutation({
    mutationFn: assistCreatePost,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectPostsProf'],
      });
      toast({
        position: 'top',
        title: 'Uspješno ste objavili novu objavu',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => createPost(lectureData);
}

export function useAssistEditPostMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editPost } = useMutation({
    mutationFn: assistEditPost,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectPostsProf'],
      });
      toast({
        position: 'top',
        title: 'Promjene su uspješno sačuvane.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      console.log(error);
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => editPost(lectureData);
}

export function useAssistDeletePostMutation(subjectID: number, yearID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deletePost } = useMutation({
    mutationFn: assistDeletePost,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectPostsProf'],
      });
      toast({
        position: 'top',
        title: 'Objava je uspjšno izbrisana.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      console.log(error);
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => deletePost(lectureData);
}

//              **** SUBJECT ACTIVITIES ****            //

export function useCreateSubjectActivityMutation(
  subjectActivityInfoID: number | undefined,
  subjectID: number | undefined,
  yearID: number | undefined,
  groupID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createSubjectActivity } = useMutation({
    mutationFn: createSubjectActivityMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [subjectActivityInfoID, 'getSubjectActivitiesAllStudents'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectActivitiesInfo'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ groupID, subjectActivityInfoID }, 'getSubjectActivitiesPerGroup'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novu aktivnost.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => createSubjectActivity(lectureData);
}

export function useEditSubjectActivityMutation(
  subjectActivityInfoID: number | undefined,
  subjectID: number | undefined,
  yearID: number | undefined,
  groupID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createSubjectActivity } = useMutation({
    mutationFn: editSubjectActivityMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectActivityInfoID, 'getSubjectActivitiesAllStudents'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectActivitiesInfo'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ groupID, subjectActivityInfoID }, 'getSubjectActivitiesPerGroup'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => createSubjectActivity(lectureData);
}

export function useDeleteSubjectActivityMutation(
  subjectActivityInfoID: number | undefined,
  subjectID: number | undefined,
  yearID: number | undefined,
  groupID: number | null
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deletePost } = useMutation({
    mutationFn: deleteSubjectActivity,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectActivityInfoID, 'getSubjectActivitiesAllStudents'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getSubjectActivitiesInfo'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ groupID, subjectActivityInfoID }, 'getSubjectActivitiesPerGroup'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (lectureData: any) => deletePost(lectureData);
}

export function useSetSubjectActivityStudentScore(subjectActivityID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: setStudentScore } = useMutation({
    mutationFn: setSubjectActivityStudentScore,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [subjectActivityID, 'getSubjectActivitiesWithStudentScores'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste unijeli bodove.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return setStudentScore;
}

export function useAddStudentsToSubjectActivity(subjectActivityID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: setStudentScore } = useMutation({
    mutationFn: addStudentsToSubjectActivity,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [subjectActivityID, 'getSubjectActivitiesWithStudentScores'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return setStudentScore;
}

//              **** EXAMS ****            //

export function useCreateExamScheduleMutation(examInfoID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createExamSchedule } = useMutation({
    mutationFn: createExamScheduleMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [examInfoID, 'getExamSchedules'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novi rok.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (examData: any) => createExamSchedule(examData);
}

export function useEditExamScheduleMutation(examInfoID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editExamSchedule } = useMutation({
    mutationFn: editExamScheduleMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examInfoID, 'getExamSchedules'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (examData: any) => editExamSchedule(examData);
}

export function useDeleteExamScheduleMutation(
  examInfoID: number | undefined,
  subjectID: number | undefined,
  yearID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteExamScheduleFunc } = useMutation({
    mutationFn: deleteExamSchedule,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examInfoID, 'getExamSchedules'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ subjectID, yearID }, 'getExamInfo'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (examScheduleData: any) => deleteExamScheduleFunc(examScheduleData);
}

export function useCreateExamMutation(
  examScheduleID: number | undefined,
  examInfoID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createExam } = useMutation({
    mutationFn: createExamMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [examInfoID, 'getExamSchedules'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => createExam(examData);
}

export function useEditExamMainInfoMutation(
  examScheduleID: number | undefined,
  examID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editExamMainInfo } = useMutation({
    mutationFn: editExamMainInfoMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [examID, 'getSingleExam'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => editExamMainInfo(examData);
}

export function useEditExamScoreInfoMutation(
  examScheduleID: number | undefined,
  examID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editExamScoreInfo } = useMutation({
    mutationFn: editExamScoreInfoMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [examID, 'getSingleExam'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => editExamScoreInfo(examData);
}

export function useEditExamDatesInfoMutation(
  examScheduleID: number | undefined,
  examID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editExamScoreInfo } = useMutation({
    mutationFn: editExamDatesInfoMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [examID, 'getSingleExam'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => editExamScoreInfo(examData);
}

export function useEditPartialExamMainInfoMutation(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editExamMainInfo } = useMutation({
    mutationFn: editPartialExamMainInfoMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [data.examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getSinglePartialExam'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => editExamMainInfo(examData);
}

export function useEditPartialExamScoreInfoMutation(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editExamScoreInfo } = useMutation({
    mutationFn: editPartialExamScoreInfoMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [data.examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getSinglePartialExam'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => editExamScoreInfo(examData);
}

export function useEditPartialExamDatesInfoMutation(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editExamScoreInfo } = useMutation({
    mutationFn: editPartialExamDatesInfoMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [data.examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getSinglePartialExam'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => editExamScoreInfo(examData);
}

export function useRepeatExamMutation(examInfoID: number | undefined, examID: number) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: repeatExamToLastSchedule } = useMutation({
    mutationFn: repeatExam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examInfoID, 'getExamSchedules'],
      });

      queryClient.invalidateQueries({
        queryKey: [data.examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [{ examInfoID, examID }, 'getLatestExamScheduleInfo'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => repeatExamToLastSchedule(examData);
}

export function useDeleteExamMutation(
  examScheduleID: number | undefined,
  examInfoID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteExamFunc } = useMutation({
    mutationFn: deleteExam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamsPreview'],
      });

      queryClient.invalidateQueries({
        queryKey: [examInfoID, 'getExamSchedules'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (examData: any) => deleteExamFunc(examData);
}

export function useCreatePartialExamMutation(examScheduleID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createPartialExam } = useMutation({
    mutationFn: createPartialExamMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamsPreview'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste kreirali novi parcijalni ispitni rok.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => createPartialExam(examData);
}

export function useDeletePartialExamMutation(examScheduleID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deletePartialExamFunc } = useMutation({
    mutationFn: deletePartialExam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamsPreview'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return (examData: any) => deletePartialExamFunc(examData);
}

export function useUpdateAllExamDatePlaceMutation(examID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createPartialExam } = useMutation({
    mutationFn: updateAllDatePlaceMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examID, 'getSingleExam'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => createPartialExam(examData);
}

export function useUpdateAllPartialExamDatePlaceMutation(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createPartialExam } = useMutation({
    mutationFn: updateAllPartialExamDatePlaceMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getSinglePartialExam"'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => createPartialExam(examData);
}

export function useSetGroupExamDatePlaceMutation(
  examID: number | undefined,
  groupID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: createPartialExam } = useMutation({
    mutationFn: setExamGroupDatePlaceMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [{ groupID, examID }, 'getExamGroupDatePlaceInfo'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste postavili datum i mjesto ispita za grupu.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => createPartialExam(examData);
}

export function useSetGroupPartExamDatePlaceMutation(
  partialExamID: number | undefined,
  groupID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: editGroupPartExamDatePlace } = useMutation({
    mutationFn: setPartExamGroupDatePlaceMutation,
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({
        queryKey: [{ groupID, partialExamID }, 'getPartExamGroupDatePlaceInfo'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => editGroupPartExamDatePlace(examData);
}

export function useSetExamStudentScore(examID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: setStudentScore } = useMutation({
    mutationFn: setExamStudentScore,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [examID, 'getExamResults'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste unijeli bodove.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return setStudentScore;
}

export function useSetPartialExamStudentScore(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: setStudentScore } = useMutation({
    mutationFn: setPartialExamStudentScore,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getPartialExamResults'],
      });

      toast({
        position: 'top',
        title: 'Uspješno ste unijeli bodove.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return setStudentScore;
}

export function useConfirmStudentExamScheduleResults(examScheduleID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: confirmStudentScore } = useMutation({
    mutationFn: confirmStudentExamScheduleResults,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamScheduleResults'],
      });

      toast({
        position: 'top',
        title: res.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return confirmStudentScore;
}

export function useDeleteStudentExamScheduleResults(examScheduleID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteStudentScore } = useMutation({
    mutationFn: deleteStudentExamScheduleResults,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [examScheduleID, 'getExamScheduleResults'],
      });

      toast({
        position: 'top',
        title: res.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return deleteStudentScore;
}

export function useStaffRegisterStudentExam(
  examID: number | undefined,
  studentID: number | null,
  subjectID: number | undefined
) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: examRegistration } = useMutation({
    mutationFn: staffRegisterStudentExamMutation,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [{ examID, studentID, subjectID }, 'checkStudentExamStatus'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (examData: any) => examRegistration(examData);
}

export function useToggleStudentExamAttendance(examID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: toggleStudentAttendance } = useMutation({
    mutationFn: toggleStudentExamAttendance,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examID, 'getExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return toggleStudentAttendance;
}

export function useToggleStudentExamNullification(examID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: toggleStudentAttendance } = useMutation({
    mutationFn: toggleStudentExamNullification,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examID, 'getExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return toggleStudentAttendance;
}

export function useAddStudentToExam(examID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: registerStudentToExam } = useMutation({
    mutationFn: addStudentToExam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examID, 'getExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return registerStudentToExam;
}

export function useDeleteStudentExamRegistration(examID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteRegistration } = useMutation({
    mutationFn: deleteStudentExamRegistration,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [examID, 'getExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return deleteRegistration;
}

export function useToggleStudentPartialExamAttendance(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: toggleStudentAttendance } = useMutation({
    mutationFn: toggleStudentPartialExamAttendance,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getPartialExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return toggleStudentAttendance;
}

export function useToggleStudentPartialExamNullification(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: toggleStudentNullification } = useMutation({
    mutationFn: toggleStudentPartialExamNullification,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getPartialExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return toggleStudentNullification;
}

export function useAddStudentToPartialExam(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: registerStudentToPartialExam } = useMutation({
    mutationFn: addStudentToPartialExam,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getPartialExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return registerStudentToPartialExam;
}

export function useDeleteStudentPartialExamRegistration(partialExamID: number | undefined) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutateAsync: deleteRegistration } = useMutation({
    mutationFn: deleteStudentPartialExamRegistration,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [partialExamID, 'getPartialExamResults'],
      });

      toast({
        position: 'top',
        title: data.message,
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error: any) => {
      toast({
        position: 'top',
        title: error.data.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return deleteRegistration;
}
