import {
  Card,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetExercisesInfo,
  useGetExercisesPerGroupInfo,
  useGetStudentsWithNoGroup,
} from '../../../services/queries';
import useProf from '../../../hooks/useProf.hooks';
import { StudentNoGroupTable } from './comp/StudentNoGroupTable';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { StudentWithGroupTable } from './comp/StudentWithGroupTable';

type Props = {};

export const StudentsGroupAllocation = (props: Props) => {
  const { groupID } = useParams();
  const navigate = useNavigate();

  const groupInfoQuery = useGetExercisesPerGroupInfo(Number(groupID));

  const handleBackClick = () => {
    navigate('/profesori/vjezbe');
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading fontSize={'xl'} pb={10}>
          Rasporedi studente
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <SimpleGrid columns={{ sm: 1, md: 1, lg: 2 }} spacing={'30px'}>
        <VStack w={{ base: '95vw', md: '400px' }}>
          <Heading fontSize={'md'} pb={1}>
            STUDENTI BEZ GRUPE
          </Heading>
          <StudentNoGroupTable />
        </VStack>
        <VStack w={{ base: '95vw', md: '400px' }}>
          <Heading fontSize={'md'} pb={1}>
            STUDENTI GRUPE - {groupInfoQuery.data?.groupName}
          </Heading>
          <StudentWithGroupTable />
        </VStack>
      </SimpleGrid>
    </Flex>
  );
};
/* 
operativni sistemi i računarstvo u oblaku
<Card
width={'270px'}
p={'15px'}
variant={'outline'}
bg={useColorModeValue('white', 'gray.900')}
>
{studentsNoGroupQuery.isLoading ? (
  <HStack w={'100%'} justify={'center'}>
    <Spinner />
  </HStack>
) : (
  studentsNoGroupQuery.data &&
  studentsNoGroupQuery.data.length > 0 && (
    <VStack>
      {studentsNoGroupQuery.data.map((student) => (
        <StudentNoGroup student={student} />
      ))}
    </VStack>
  )
)}
</Card> */
