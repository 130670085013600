import {
  Button,
  HStack,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { BlueButton } from '../../../../components/general/BlueButton';
import useProf from '../../../../hooks/useProf.hooks';
import { AcademicYear } from '../../../../models/subject';
import { useGetAcademicYears, useGetExercisesInfo } from '../../../../services/queries';
import { CreateGroupModal } from './CreateGroupModal';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth.hooks';
import { useState } from 'react';

type Props = {};

export const ExercisesInfo = (props: Props) => {
  const profContext = useProf();
  const authContext = useAuth();
  const years = useGetAcademicYears(profContext.selectedSubjectID);
  const navigate = useNavigate();
  const {
    isOpen: isCreateGroupOpen,
    onOpen: onCreateGroupOpen,
    onClose: onCreateGroupClose,
  } = useDisclosure();

  const exercisesInfoQuery = useGetExercisesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
    exercisesInfoQuery.refetch();
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w={{ base: '95%', md: '85%', lg: '700px' }}
      justify='space-around'
      pb={10}
    >
      <CreateGroupModal isOpen={isCreateGroupOpen} onClose={onCreateGroupClose} />
      <VStack align={'start'} spacing={0}>
        {years.isLoading ? (
          <HStack w={'100%'} justify={'center'}>
            <Spinner />
          </HStack>
        ) : (
          years.data &&
          years.data.length > 0 && (
            <Select
              w={{ base: '95%', sm: '263px', md: '263px' }}
              id={'AcademicYearSelector'}
              colorScheme='blue'
              onChange={handleOnChangeAcademicYear}
            >
              {years.data.map((year: AcademicYear) => (
                <option key={year.id} value={year.id}>
                  {year.currentYear ? `${year.year} - tekuća` : year.year}
                </option>
              ))}
            </Select>
          )
        )}

        {exercisesInfoQuery.isLoading ? (
          <HStack w='263px' h='132px' justify={'center'}>
            <Spinner />
          </HStack>
        ) : exercisesInfoQuery.data ? (
          <>
            <HStack align={'center'} pt={2}>
              <Text>Broj Časova: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {exercisesInfoQuery.data.classHours}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Težinski faktor: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {exercisesInfoQuery.data.difficultyFactor}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Da li je prisustvo uslov za potpis: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {exercisesInfoQuery.data.attendanceReqForSignature ? 'DA' : 'NE'}
              </Text>
            </HStack>

            {exercisesInfoQuery.data.attendanceReqForSignature && (
              <HStack align={'center'}>
                <Text> Broj dozvoljenih izostanaka:</Text>
                <Text fontWeight={'700'} fontSize={'lg'}>
                  {exercisesInfoQuery.data.maxAllowedAbsence}
                </Text>
              </HStack>
            )}
          </>
        ) : (
          <VStack align={'start'}>
            <Text>Kreirana je nova akademska godina.</Text>
            <Text>Da li želite mjenjati podatke o vježbama?</Text>
            <Button w={'100%'} bgColor={'blue.600'}>
              Uvezi iz prošle godine
            </Button>
            <Button w={'100%'} bgColor={'blue.600'}>
              Unesi nove podatke
            </Button>
          </VStack>
        )}
      </VStack>
      <Stack pt={{ base: 10, md: 0 }} direction={{ base: 'row', md: 'column' }} justify={'center'}>
        <BlueButton
          buttonText='Dodaj grupu'
          onClick={onCreateGroupOpen}
          isDisabled={authContext.user?.role === 'PROFESSOR'}
        />
        <BlueButton
          buttonText='Studenti'
          onClick={() => {
            navigate('/profesori/studenti');
          }}
        />
      </Stack>
    </Stack>
  );
};
