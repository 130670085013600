import * as yup from 'yup';

export const createGroupSchema = yup.object({
  academicYearID: yup.number().required(),
  name: yup.string().required('Ime je obavezno').trim(),
  exerciseInfoID: yup.number().required(),
});

export const groupIDSchema = yup.object({
  groupID: yup.number().required(),
});

export const assignStudentToSubjectSchema = yup.object({
  groupID: yup.number().required(),
  studentID: yup.number().required(),
  academicYearID: yup.number().required(),
});

export const updateExerciseSchema = yup.object({
  exerciseID: yup.number().required(),
  name: yup.string().required('Ime je obavezno').trim(),
  date: yup.string().required('Datum je obazean').trim(),
  classHours: yup
    .number()
    .required('Broj Časova je obavezan')
    .positive('Broj časova mora biti pozitivan broj.'),
});

export const createExerciseSchema = yup.object({
  groupID: yup.number().required(),
  name: yup.string().required('Ime je obavezno').trim(),
  date: yup.string().required('Datum je obazean').trim(),
  classHours: yup
    .number()
    .required('Broj Časova je obavezan')
    .positive('Broj časova mora biti pozitivan broj.'),
  assistants: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      selected: yup.bool().required(),
    })
  ),
});

export type createGroupDto = yup.InferType<typeof createGroupSchema>;
export type groupIDDto = yup.InferType<typeof groupIDSchema>;
export type assignStudentToSubjectDto = yup.InferType<typeof assignStudentToSubjectSchema>;
export type updateExerciseDto = yup.InferType<typeof updateExerciseSchema>;
export type createExerciseDto = yup.InferType<typeof createExerciseSchema>;
