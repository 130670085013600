import {
  Flex,
  HStack,
  Heading,
  Select,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import useProf from '../../../hooks/useProf.hooks';
import { useGetAcademicYears, useGetSubjectPostsProf } from '../../../services/queries';
import { AcademicYear } from '../../../models/subject';
import { LectureAttendancesStatTable } from './comp/LectureAttendanceStatTable';
import { ExerciseAttendancesStatTable } from './comp/ExerciseAttendanceStatTable';
import { StaffPost } from './comp/Post';
import { ProfCreatePost } from './comp/ProfCreatePost';
import useAuth from '../../../hooks/useAuth.hooks';
import { AssistCreatePost } from './comp/AssistCreatePost';

type Props = {};

export const ProfPostsPage = (props: Props) => {
  const navigate = useNavigate();
  const profContext = useProf();
  const authContext = useAuth();
  const years = useGetAcademicYears(profContext.selectedSubjectID);
  const postsData = useGetSubjectPostsProf(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const handleBackClick = () => {
    navigate('/profesori/studenti');
  };

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      justify={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading pb={4} fontSize={'xl'}>
          Objave
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <VStack pt={5} spacing={5} w={{ base: '90vw', md: '500px' }}>
        {years.isLoading ? (
          <HStack w={'100%'} justify={'center'}>
            <Spinner />
          </HStack>
        ) : (
          years.data &&
          years.data.length > 0 && (
            <Select
              id={'AcademicYearSelector'}
              colorScheme='blue'
              onChange={handleOnChangeAcademicYear}
            >
              {years.data.map((year: AcademicYear) => (
                <option key={year.id} value={year.id}>
                  {year.currentYear ? `${year.year} - tekuća` : year.year}
                </option>
              ))}
            </Select>
          )
        )}
        {authContext.user?.role === 'PROFESSOR' ? <ProfCreatePost /> : <AssistCreatePost />}
        {postsData.isLoading ? (
          <HStack w={'100%'} justify={'center'}>
            <Spinner />
          </HStack>
        ) : postsData.data && postsData.data.length > 0 ? (
          <VStack w={'100%'} align={'center'} justify={'center'} spacing={5}>
            {postsData.data.map((post) => (
              <StaffPost key={`staffPost-${post.id}`} post={post} />
            ))}
          </VStack>
        ) : (
          <p>Nema objava</p>
        )}
      </VStack>
    </Flex>
  );
};
