import * as yup from 'yup';

export const editDifficultyFactorsSchema = yup.object({
  subjectID: yup.number().required('ID predmeta je obavezan!'),
  lecturesDF: yup
    .number()
    .required('Težinski faktor predavanja je obavezan!')
    .integer('Težinski faktor mora biti cijeli broj')
    .min(0, 'Težinski faktor ne može biti manji od 0!')
    .max(100, 'Težinski faktor ne može biti veći od 100!'),
  exercisesDF: yup
    .number()
    .required('Težinski faktor vježbi je obavezan!')
    .integer('Težinski faktor mora biti cijeli broj')
    .min(0, 'Težinski faktor ne može biti manji od 0!')
    .max(100, 'Težinski faktor ne može biti veći od 100!'),
  subjectActivitiesAllDF: yup
    .number()
    .required('Težinski faktor aktivnosti za sve je obavezan!')
    .integer('Težinski faktor mora biti cijeli broj')
    .min(0, 'Težinski faktor ne može biti manji od 0!')
    .max(100, 'Težinski faktor ne može biti veći od 100!'),
  subjectActivitiesGroupDF: yup
    .number()
    .required('Težinski faktor aktivnosti po grupi je obavezan!')
    .integer('Težinski faktor mora biti cijeli broj')
    .min(0, 'Težinski faktor ne može biti manji od 0!')
    .max(100, 'Težinski faktor ne može biti veći od 100!'),
  examsDF: yup
    .number()
    .required('Težinski faktor ispita je obavezan!')
    .integer('Težinski faktor mora biti cijeli broj')
    .min(0, 'Težinski faktor ne može biti manji od 0!')
    .max(100, 'Težinski faktor ne može biti veći od 100!'),
});

export const editLecturesExercisesInfoSchema = yup.object({
  subjectID: yup.number().required().positive(),
  classHours: yup
    .number()
    .required()
    .min(0, 'Minimalan broj časova je 0')
    .max(100, 'Maksimalan broj časova je 100'),
  attendanceReqForSignature: yup.boolean().required(),
  maxAllowedAbsence: yup.number().required().positive().max(7, 'Maksimalan broj izostanaka je 7'),
});

export const editSubjectMainInfoSchema = yup.object({
  subjectID: yup.number().required().positive(),
  name: yup
    .string()
    .trim()
    .required('Ime ispita je obavezno polje')
    .max(45, 'Maksimalan broj karaktera je 45'),
  ECTSpoints: yup
    .number()
    .required('ECTS poeni su obavezno polje')
    .positive('ECTS poeni moraju biti pozitivni')
    .max(15, 'ECTS poeni ne mogu biti veći od 15'),
  semester: yup
    .number()
    .required('Semestar je obavezno polje')
    .positive('Semestar ne može biti manji od 1')
    .max(13, 'Semestar ne može biti veći od 12'),
});

export const deleteSubjectSchema = yup.object({
  subjectID: yup.number().required().positive(),
  QRCode: yup
    .string()
    .trim()
    .required('Šifra predmeta je obavezno polje.')
    .max(8, 'Šifra predmeta mora imati 8 karaktera')
    .min(8, 'Šifra predmeta mora imati 8 karaktera'),
});

export const ToggleStudentSignatureSchema = yup.object({
  subjectID: yup.number().required().positive(),
  studentID: yup.number().required().positive(),
  comment: yup
    .string()
    .trim()
    .required('Komentar je obavezno polje.')
    .max(150, 'Maksimalan broj karaktera je 150'),
});

export const setStudentAdditionalPoints = yup.object({
  subjectID: yup.number().required().positive(),
  studentID: yup.number().required().positive(),
  additionalPoints: yup
    .number()
    .required('Dodatni bodovi su obavezno polje.')
    .min(0, 'Minimalan broj dodatnih bodova je 0'),
  additionalPointsComment: yup
    .string()
    .trim()
    .max(200, 'Maksimalan broj karaktera je 200')
    .nullable(),
});

export type editDifficultyFactorsDto = yup.InferType<typeof editDifficultyFactorsSchema>;
export type editLecturesExercisesInfoDto = yup.InferType<typeof editLecturesExercisesInfoSchema>;
export type editSubjectMainInfoDto = yup.InferType<typeof editSubjectMainInfoSchema>;
export type deleteSubjectDto = yup.InferType<typeof deleteSubjectSchema>;
export type ToggleStudentSignatureDto = yup.InferType<typeof ToggleStudentSignatureSchema>;
export type setStudentAdditionalPointsDto = yup.InferType<typeof setStudentAdditionalPoints>;
