import {
  Avatar,
  Box,
  Card,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { StudentCR, studentCompleteReport } from '../../../../models/student-complete-report';
import { BlueButton } from '../../../../components/general/BlueButton';
import { RedButton } from '../../../../components/general/RedButton';
import { EditIcon } from '@chakra-ui/icons';
import { AdditionalPointsModal } from './AdditionalPointsModal';
import { useToggleStudentHasCompletedSubject } from '../../../../services/mutations';
import useProf from '../../../../hooks/useProf.hooks';
import { fi } from 'date-fns/locale';

type Props = {
  student: StudentCR;
  studentComplete: studentCompleteReport;
};

export const StudentInfo = ({ student, studentComplete }: Props) => {
  const profcontext = useProf();
  const toast = useToast();

  const [loadingCompleteSubject, setLoadingCompleteSubject] = useState(false);

  const toggleStudentcompleteSubject = useToggleStudentHasCompletedSubject(
    profcontext.selectedSubjectID,
    student.id
  );

  const {
    isOpen: isAdditionalPointsModalOpen,
    onOpen: onAdditionalPointsModalOpen,
    onClose: onAdditionalPointsModalClose,
  } = useDisclosure();
  return (
    <Box w={{ base: '90vw', md: '300px' }} mx='auto' justifyContent={'center'} h={'100%'}>
      <AdditionalPointsModal
        student={student}
        isOpen={isAdditionalPointsModalOpen}
        onClose={onAdditionalPointsModalClose}
      />
      <Card p={3} w={'100%'}>
        <Flex direction='column' align='center' mb={5}>
          <Avatar
            w={'150px'}
            h={'150px'}
            name={student.firstName + ' ' + student.lastName}
            src={student.profileImage}
            mb={3}
          />
          <Text mb={2} fontSize='xl' fontWeight='bold'>
            {student.firstName + ' ' + student.lastName}
          </Text>
          <Text pb={5} fontSize='md'>
            {student.email}
          </Text>
          <HStack>
            <Text>Broj indexa:</Text>
            <Text fontWeight='bold'>{student.indexNumber}</Text>
          </HStack>
          <HStack>
            <Text>Grupa:</Text>
            <Text fontWeight='bold'>{student.groupName ?? 'Nema'}</Text>
          </HStack>
          <VStack w={'100%'} pt={2}>
            <Card
              w={'100%'}
              p={3}
              align={'center'}
              variant={'outline'}
              bg={useColorModeValue('white', 'gray.900')}
            >
              <Box position='absolute' top='0' right='1' p='5px'>
                <EditIcon
                  color={'white'}
                  boxSize={4}
                  onClick={() => {
                    if (!student.hasCompletedSubject) {
                      onAdditionalPointsModalOpen();
                    } else {
                      toast({
                        position: 'top',
                        title: 'Student je položio predmet',
                        status: 'warning',
                        duration: 3000,
                      });
                    }
                  }}
                  _hover={{ cursor: 'pointer', color: 'green.500' }}
                />
              </Box>
              <HStack justify={'center'}>
                <Heading fontSize={'lg'} pb={2}>
                  Dodatni bodovi
                </Heading>
              </HStack>
              <VStack spacing={0}>
                <Text>Komentar:</Text>
                <Text w={'100%'} whiteSpace={'pre-line'}>
                  {student.additionalPointsComment}
                </Text>
              </VStack>

              <HStack>
                <Text>Dodatni bodovi:</Text>
                <Text fontWeight='bold'>{student.additionalPoints}</Text>
              </HStack>
            </Card>
          </VStack>
          <HStack justify={'center'}>
            <Heading fontSize={'lg'} py={7}>
              Zbir bodova - {student.scoresSum}
            </Heading>
          </HStack>
          <Text textAlign={'center'} w={{ base: '95vw', md: '100%' }}>
            Preporučena konačna ocjena
          </Text>
          <Text textAlign={'center'} w={{ base: '95vw', md: '100%' }}>
            za rezultat: {student.scoresSum} / 100
          </Text>
          <Text pb={5} fontSize={'5xl'} fontWeight={'bold'}>
            {student.grade}
          </Text>
          {student.hasCompletedSubject ? (
            <>
              <RedButton
                buttonText='Poništi ocjenu'
                height='30px'
                isDisabled={!studentComplete.examResults && !studentComplete.exercisesInfo}
                isLoading={loadingCompleteSubject}
                onClick={async () => {
                  try {
                    setLoadingCompleteSubject(true);
                    await toggleStudentcompleteSubject({
                      studentID: student.id,
                      subjectID: profcontext.selectedSubjectID,
                    });
                  } catch (error) {
                  } finally {
                    setTimeout(() => {
                      setLoadingCompleteSubject(false);
                    }, 1000);
                  }
                }}
              />
              <Text color={'green.400'} fontWeight={'bold'} pt={2}>
                Student je položio predmet
              </Text>
            </>
          ) : (
            <BlueButton
              buttonText='Unesi ocjenu'
              height='30px'
              isDisabled={!studentComplete.examResults && !studentComplete.exercisesInfo}
              isLoading={loadingCompleteSubject}
              onClick={async () => {
                try {
                  setLoadingCompleteSubject(true);
                  await toggleStudentcompleteSubject({
                    studentID: student.id,
                    subjectID: profcontext.selectedSubjectID,
                  });
                } catch (error) {
                } finally {
                  setTimeout(() => {
                    setLoadingCompleteSubject(false);
                  }, 1000);
                }
              }}
            />
          )}
        </Flex>
      </Card>
    </Box>
  );
};
