import {
  Avatar,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSubjectActivitiesWithStudentScores } from '../../../services/queries';
import { BlueButton } from '../../../components/general/BlueButton';
import { SubjectActivityStudentTable } from './comp/subjectActivityStudentTable';
import { useAddStudentsToSubjectActivity } from '../../../services/mutations';
import { useState } from 'react';

type Props = {};

export const SubjectActivityScores = (props: Props) => {
  const { subjectActivityID } = useParams();
  const [addStudentsLoading, setAddStudentsLoading] = useState(false);

  const subjectActivityInfo = useGetSubjectActivitiesWithStudentScores(Number(subjectActivityID));
  const addStudents = useAddStudentsToSubjectActivity(Number(subjectActivityID));

  const navigate = useNavigate();
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'} align={'center'} pb={5}>
        <HStack>
          <ArrowBackButton
            onClick={() => {
              navigate(
                !subjectActivityInfo.data?.groupID
                  ? '/profesori/dodatne-aktivnosti/'
                  : `/profesori/dodatne-aktivnosti/grupa/${subjectActivityInfo.data.groupID}`
              );
            }}
          />
        </HStack>
        <Heading textAlign={'center'} fontSize={'xl'}>
          Rezultati aktivnosti
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      {subjectActivityInfo.isLoading ? (
        <HStack h={{ base: '15vh', md: '25vh' }} w={'50%'} justify={'Center'}>
          <Spinner />
        </HStack>
      ) : subjectActivityInfo.data ? (
        <VStack w={{ base: '90vw', md: '100%' }}>
          <Stack
            w={{ base: '90vw', md: '100%', lg: '800px' }}
            direction={{ base: 'column', md: 'row' }}
            justify={'space-around'}
            align={'center'}
            pb={5}
          >
            <VStack align={{ base: 'center', md: 'start' }} spacing={0}>
              <Text>Naziv aktivnosti: {subjectActivityInfo.data.name}</Text>
              <Text>Broj bodova za prolaz: {subjectActivityInfo.data.minPassingScore}</Text>
              <VStack>
                <Heading pt={2} fontSize={'md'}>
                  Vodilac aktivnosti
                </Heading>
                <HStack>
                  <Avatar size={'sm'} src={subjectActivityInfo.data.creator.profileImage} />
                  <Text>
                    {subjectActivityInfo.data.creator.firstName}{' '}
                    {subjectActivityInfo.data.creator.lastName}
                  </Text>
                </HStack>
              </VStack>
            </VStack>
            <VStack pt={{ base: 5, md: 0 }} spacing={0}>
              <BlueButton
                buttonText='Dodaj sve studente'
                onClick={() => {
                  setAddStudentsLoading(true);
                  addStudents({ subjectActivityID: Number(subjectActivityID) })
                    .catch((err) => {})
                    .finally(() => {
                      setTimeout(() => {
                        setAddStudentsLoading(false);
                      }, 1000);
                    });
                }}
                isLoading={addStudentsLoading}
              />
            </VStack>
          </Stack>
          <SubjectActivityStudentTable
            students={subjectActivityInfo.data.students}
            maxScore={subjectActivityInfo.data.maxScore}
            minPassingScore={subjectActivityInfo.data.minPassingScore}
            creator={{
              id: subjectActivityInfo.data.creator.id,
              role: subjectActivityInfo.data.creator.role,
            }}
          />
        </VStack>
      ) : (
        <VStack>
          <Heading pt={10} pb={3} fontSize={'md'}>
            Dodatne aktivnosti nisu pronađene ili nemate pristup istim.
          </Heading>
          <BlueButton
            buttonText='Vrati se na dodatne aktivnosti'
            onClick={() => {
              navigate('/profesori/dodatne-aktivnosti');
            }}
          />
        </VStack>
      )}
    </Flex>
  );
};
